<template>
  <div class="order-card-skeleton grid grid-cols-12">
    <div class="col-span-2 smd:col-span-2 order-table-cells info"></div>
    <div class="info col-span-4 smd:col-span-3"></div>
    <div class="info smd:block hidden smd:col-span-3"></div>
    <div class="col-span-2 smd:col-span-2 order-table-cells info"></div>
    <div
      class="
        col-span-4
        smd:col-span-2
        order-table-cells
        text-borderColor
        cursor-pointer
        lora-bold
        info
      "
    ></div>
  </div>
</template>