<template>
  <p>
    {{ formated }}
  </p>
</template>

<script>
export default {
  props: ["date"],
  computed: {
    formated() {
      return `${this.date.split(",")[2]}/${this.date.split(",")[1]}/${
        this.date.split(",")[0]
      }`;
    },
  },
};
</script>