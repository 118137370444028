<template>
  <div class="order-card grid grid-cols-12">
    <div class="col-span-2 smd:col-span-2 order-table-cells">
      {{ content.order }} #{{ id }}
    </div>
    <div class="col-span-4 smd:col-span-3 order-table-cells">
      <OrderDate :date="date" />
    </div>
    <div class="smd:block hidden smd:col-span-3 order-table-cells">
      <OrderStatus :status="status" />
    </div>
    <div v-if="total" class="col-span-2 smd:col-span-2 order-table-cells">
      {{ format(parseFloat(total) + parseFloat(delivery)) }}
      {{ content.iqd }}
    </div>
    <div v-else class="col-span-2 smd:col-span-2 order-table-cells">---</div>
    <div
      class="
        col-span-4
        smd:col-span-2
        order-table-cells
        text-borderColor
        cursor-pointer
        lora-bold
      "
    >
      <router-link :to="{ name: routeName, params: { type: type, id: id } }">
        {{ content.viewOrder }}
      </router-link>
    </div>
  </div>
</template>

<script>
import OrderStatus from "../../../components/ui/Order/OrderStatus.vue";
import OrderDate from "../../../components/ui/Order/OrderDate.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: { OrderStatus, OrderDate },
  props: ["total", "delivery", "status", "date", "id", "type", "routeName"],
  inject: ["content"],
  methods: {
    format(number) {
      return format(number);
    },
  },
};
</script>
