<template>
  <div class="flex justify-between flex-wrap items-center">
    <div class="page-title me-4 mb-3">{{ content.yourOrderHistory }}</div>
    <div class="flex items-center flex-wrap">
      <Pill
        class="me-4 mb-3"
        :class="{ 'active-pill': selectedSection == 'shopItems' }"
        :text="content.shopItems"
        @click="selectedSection = 'shopItems'"
      />
      <Pill
        class="me-4 mb-3"
        :class="{ 'active-pill': selectedSection == 'workshops' }"
        :text="content.workshops"
        @click="selectedSection = 'workshops'"
      />
      <!-- <Pill
        class="me-4 mb-3"
        :class="{ 'active-pill': selectedSection == 'refills' }"
        :text="content.refills"
        @click="selectedSection = 'refills'"
      /> -->
      <Pill
        class="me-4 mb-3"
        :class="{ 'active-pill': selectedSection == 'subscriptions' }"
        :text="content.subscriptions"
        @click="selectedSection = 'subscriptions'"
      />
      <Pill
        class="mb-3"
        :class="{ 'active-pill': selectedSection == 'personalized' }"
        :text="content.personalized"
        @click="selectedSection = 'personalized'"
      />
    </div>
  </div>
  <div class="order-history-container">
    <div class="mt-12 grid grid-cols-12 w-full order-table-header">
      <div class="col-span-2 smd:col-span-2 order-table-cells">#</div>
      <div class="col-span-4 smd:col-span-3 order-table-cells">
        {{ content.date }}
      </div>
      <div class="smd:block hidden smd:col-span-3 order-table-cells">
        {{ content.status }}
      </div>
      <div class="col-span-2 smd:col-span-2 order-table-cells">
        {{ content.total }}
      </div>
      <div class="col-span-4 smd:col-span-2 order-table-cells"></div>
    </div>
    <OrderCardSkeleton v-if="loading" />
    <div v-else-if="selectedSection == 'workshops'">
      <p class="text-2xl text-center mt-10 text-borderColor" v-if="workshops?.length == 0">{{ content.noOrders }}</p>
      <OrderCard
        v-for="item in workshops"
        :key="item.id"
        :id="item.id"
        type="workshops"
        :date="item.date"
        :status="item.status"
        :total="item.ticket.price"
        :delivery="0"
        route-name="ticketOrderDetails"
      />
    </div>
    <div v-else-if="selectedSection == 'shopItems'">
      <p class="text-2xl text-center mt-10 text-borderColor" v-if="orders.length == 0">{{ content.noOrders }}</p>
      <OrderCard
        v-for="item in orders"
        :key="item.id"
        :id="item.id"
        type="shop"
        :date="item.date"
        :status="item.status"
        :total="item.total"
        :delivery="item?.city?.price ?? 0"
        route-name="orderDetails"
      />
    </div>
    <!-- <div v-else-if="selectedSection == 'refills'">
      <p class="text-2xl text-center mt-10 text-borderColor" v-if="refills.length == 0">{{ content.noOrders }}</p>
      <OrderCard
        v-for="item in refills"
        :key="item.id"
        :id="item.id"
        type="refills"
        :date="item.date"
        :status="item.status"
        :total="item.total"
        :delivery="item?.city?.price ?? 0"
        route-name="refillOrderDetails"
      />
    </div> -->
    <div v-else-if="selectedSection == 'subscriptions'">
      <p class="text-2xl text-center mt-10 text-borderColor" v-if="subscriptions.length == 0">{{ content.noOrders }}</p>
      <OrderCard
        v-for="item in subscriptions"
        :key="item.id"
        :id="item.id"
        type="subscriptions"
        :date="item.date"
        :status="item.status"
        :total="item.total"
        :delivery="item?.city?.price ?? 0"
        route-name="subscriptionOrderDetails"
      />
    </div>
    <div v-else-if="selectedSection == 'personalized'">
      <p class="text-2xl text-center mt-10 text-borderColor" v-if="personalized.length == 0">{{ content.noOrders }}</p>
      <OrderCard
        v-for="item in personalized"
        :key="item.id"
        :id="item.id"
        type="personalized"
        :date="item.date"
        :status="item.status"
        :total="item.price"
        :delivery="item?.city?.price ?? 0"
        route-name="personalizedOrderDetails"
      />
    </div>
  </div>
</template>

<script>
import Pill from "/src/components/ui/Pill.vue";
import OrderCard from "./OrderCard.vue";
import OrderCardSkeleton from "/src/components/ui/Skeletons/OrderCardSkeleton.vue";

export default {
  components: {
    Pill,
    OrderCard,
    OrderCardSkeleton,
  },
  inject: ["content"],
  data() {
    return {
      loading: true,
      selectedSection: "shopItems",
    };
  },
  computed: {
    orders() {
      return this.$store.getters["orders/orders"];
    },
    workshops() {
      return this.$store.getters["workshops/tickets"];
    },
    // refills() {
    //   return this.$store.getters["refill/refills"];
    // },
    personalized() {
      return this.$store.getters["personalized/requests"];
    },
    subscriptions() {
      return this.$store.getters["subscription/mySubs"];
    },
  },
  async mounted() {
    await this.$store.dispatch("orders/orders");
    await this.$store.dispatch("workshops/myTickets");
    // await this.$store.dispatch("refill/refills");
    await this.$store.dispatch("subscription/mySubscriptions");
    await this.$store.dispatch("personalized/requests");
    this.loading = false;
  },
};
</script>
